import React from "react";
import Cta from "../components/sections/Cta";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import HeroSplit from "../components/sections/HeroSplit";
import Testimonial from "../components/sections/Testimonial";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSplit imageFill className="illustration-section-01" />
        {/* <Clients topDivider bottomDivider /> */}
        <FeaturesTiles />
        <FeaturesSplit invertMobile imageFill />
        <Testimonial topDivider />
        {/* <Pricing hasBgColor topDivider pricingSwitcher /> */}
        <Cta hasBgColor invertColor topDivider bottomDivider split />
      </React.Fragment>
    );
  }
}

export default Home;
