import classNames from "classnames";
import React from "react";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tiles section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "",
      paragraph: "",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image tertiary-color mb-16 reveal-scale-up"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                    >
                      iOS Expert
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="150"
                    >
                      With 7 years native iOS experience, David has mastered all
                      aspect of development within Apple ecosystem.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image secondary-color mb-16 reveal-scale-up"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-02.svg")}
                        alt="Features tile icon 02"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                    >
                      Flutter
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="150"
                    >
                      David is a talented specialist of the Google-made
                      cross-platform mobile engine
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image tertiary-color mb-16 reveal-scale-up"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-03.svg")}
                        alt="Features tile icon 03"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                    >
                      React & Web
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="150"
                    >
                      Looking for an extension to your React application? David
                      has years of React experience under His belt.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image secondary-color mb-16 reveal-scale-up"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-04.svg")}
                        alt="Features tile icon 04"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                    >
                      AWS & Cloud
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="150"
                    >
                      David can get your app, website or API to anyone, anywhere
                      in the world. Accomplished AWS Professional.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image tertiary-color mb-16 reveal-scale-up"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-05.svg")}
                        alt="Features tile icon 05"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                    >
                      Node & Typescript
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="150"
                    >
                      Looking for a beautiful structured Node-Typescript API?
                      This is a specialty of David.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image secondary-color mb-16 reveal-scale-up"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-06.svg")}
                        alt="Features tile icon 06"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                    >
                      Android, UX & More
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-bottom"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="150"
                    >
                      David is a multi-disciplined unicorn developer, He can
                      conceptualise and realise almost anything you have in
                      mind.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
