import classNames from "classnames";
import React from "react";
import { SectionSplitProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: "Meet PavR",
      paragraph:
        "A summary of recent projects and clients, the challenges and outcomes",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-left"
                    data-reveal-container=".split-item"
                  >
                    Storyboard
                  </h3>
                  <p
                    className="m-0 reveal-from-left"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    A fully fledged social media app written in Swift. David
                    worked as the lead iOS engineer of this ambitious Social
                    Media app.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-03 reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    src={require("./../../assets/images/features-split-image.svg")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                  <div style={imgOddStyle}>
                    <Image
                      src={require("./../../assets/images/features-split-top-01.png")}
                      alt="Features split top 01"
                      width={700}
                      height={583}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-right"
                    data-reveal-container=".split-item"
                  >
                    Reforest
                  </h3>
                  <p
                    className="m-0 reveal-from-right"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    <a href="https://reforest.com.au/">Reforest</a> is a
                    exciting and fresh take on a carbon offsetting, allowing
                    consumers to plant real trees to match their emissions.
                    David developed the demanding Reforest Flutter with quality
                    at the forefront of each decision. Today the Reforest app is
                    regarded as one of the most engaging apps of it's kind.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-04 reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="large-shadow"
                    src={require("./../../assets/images/reforest.png")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-left"
                    data-reveal-container=".split-item"
                  >
                    CrowdCanvas
                  </h3>
                  <p
                    className="m-0 reveal-from-left"
                    data-reveal-delay="100"
                    data-reveal-container=".split-item"
                  >
                    CrowdCanvas™ has developed a value-add phone application
                    offered to audiences allowing its users real-time
                    interaction with large stadium sized concert entertainment,
                    where the audience becomes an active participant in the
                    show. David developed the prototype and MVP for this
                    technology.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-05 reveal-scale-up",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    className="large-shadow"
                    src={require("./../../assets/images/crowdcanvas.jpeg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgOddStyle = {
  position: "absolute",
  width: "132.57%",
  maxWidth: "132.57%",
  top: "-20.2%",
  left: "-16.1%",
};

const imgEvenStyle = {
  position: "absolute",
  width: "132.57%",
  maxWidth: "132.57%",
  top: "-20.2%",
  left: "-16.6%",
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
