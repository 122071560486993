import axios from "axios";
import React from "react";
import Button from "../components/elements/Button";
import Input from "../components/elements/Input";
import Modal from "../components/elements/Modal";
import GenericSection from "../components/sections/GenericSection";
// import sections
import HeroFull from "../components/sections/HeroFull";

class Secondary extends React.Component {
  state = {
    demoModalActive: false,
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });

    this.props.history.goBack();
  };

  onSubmit = async (e) => {
    e.preventDefault();

    setTimeout(() => {
      this.setState({ demoModalActive: true });
    }, 300);

    const request = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      api_key: "x72Dr487W66YZ5DTh7JXCujci0F1ASD8hsdfS",
    };
    axios.post(
      "https://0f9s9rf5ec.execute-api.ap-southeast-2.amazonaws.com/prod/contact",
      request
    );
  };

  inputChanged = (key) => (e) => {
    this.setState({ [key]: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <HeroFull className="illustration-section-02" />

        <GenericSection>
          <div className="container-xs">
            <form style={formStyle} onSubmit={this.onSubmit}>
              <div className="mb-24">
                <Input
                  onChange={this.inputChanged("name")}
                  label="Your Name"
                ></Input>
              </div>
              <div className="mb-24">
                <Input
                  type="textarea"
                  label="What are you looking for?"
                  placeholder="Your ideas and hopes"
                  rows={5}
                  onChange={this.inputChanged("message")}
                ></Input>
              </div>
              <div className="mb-24">
                <Input
                  type="email"
                  label="This is a label"
                  placeholder="Your best email.."
                  formGroup="desktop"
                  onChange={this.inputChanged("email")}
                  labelHidden
                >
                  <Button onClick={this.onSubmit} color="primary">
                    Send
                  </Button>
                </Input>
              </div>
              <sub>All requests will be responded to within 24 hours.</sub>
            </form>
          </div>
        </GenericSection>

        <div className="spacer-64 spacer-24-mobile has-bg-color"></div>
        <div className="spacer-24 has-bg-color"></div>

        <Modal
          id="demo-modal"
          show={this.state.demoModalActive}
          handleClose={this.closeModal}
        >
          <div className="center-content">
            <h3 className="mt-32 mb-12">Inquiry Sent</h3>
            <p className="text-sm">
              Your inquiry has been received and we will be in contact within 24
              hours.
            </p>
          </div>

          <div className="center-content mt-24">
            <a
              className="func-link text-xxs fw-500 tt-u"
              aria-label="close"
              href="#0"
              onClick={this.closeModal}
            >
              Okay
            </a>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: "420px",
  margin: "0 auto",
};

export default Secondary;
